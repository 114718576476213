import Vue from "vue";
import VueRouter from "vue-router";
// import { component } from "vue/types/umd";
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

// 路由懒加载
const Login = () => import("../views/Login.vue");
const Index = () => import("../views/Index.vue");
const Welcome = () => import("../views/auth/index/Welcome.vue");
const RoleMenu = () => import("../views/auth/roleMenu/RoleMenu.vue");

const employeeManagement = () =>
  import("../views/auth/employee/EmployeeManagement.vue");
const employeeDetail = () =>
  import("../views/auth/employee/EmployeeDetail.vue");
const OrganizationManagement = () =>
  import("../views/auth/organization/OrganizationManagement.vue");
const Post = () => import("../views/auth/post/Post.vue");
const Menu = () => import("../views/auth/menu/Menu.vue");
const Authority = () => import("../views/auth/authority/Authority.vue");
const OrganizationTypeMaintenance = () =>
  import("../views/auth/organizationType/OrganizationType.vue");
const myAccountView = () => import("../views/auth/my/MyAccountView.vue");
const SystemConfig = () => import("../views/systemConfig/SystemConfig.vue");
const SystemConfigDetails = () =>
  import("../views/systemConfig/SystemConfigDetails.vue");
const TableDesign = () => import("../views/coding/tableDesign/TableDesign.vue");
const Table = () => import('../views/coding/table/Table.vue')
const TableDetails = () => import('../views/coding/table/TableDetails.vue')
const TableDdl = () => import('../views/coding/tableDdl/TableDdl.vue')
const TableDdlDetails = () => import('../views/coding/tableDdl/TableDdlDetails.vue')
// const FromDesigner = () => import('../views/fromDesigner/FromDesigner.vue')


const LoginLog = () => import('../views/loginLog/LoginLog.vue')
const LoginLogDetails = () => import('../views/loginLog/LoginLogDetails.vue')

//design
const FromDesigner = () => import('../components/formDesigner.vue')
const DialogTest = () => import('../views/fromDesigner/dialogTest.vue')
const QueryDialogTest = () => import('../views/fromDesigner/queryDialogTest.vue')
const View = () => import('../views/fromDesigner/view.vue')
const DesignTable = () => import('../views/fromDesigner/table.vue')
//经营数据
const platformdata = () => import('../views/businessData/platformData.vue')
const auditBusinessone = () => import("../views/businessData/auditBusinessone.vue");
const auditdetail = () => import("../views/businessData/auditdetail.vue");
const auditBusinesstwo = () => import("../views/businessData/auditBusinesstwo.vue");
const auditdetailtwo = () => import("../views/businessData/auditdetailtwo.vue");
const exChange = () => import("../views/businessData/exChange.vue");
const tip = () => import("../views/businessData/tip.vue");

//商家管理
const diamond = () => import('../views/merchantManagement/diamond.vue')
const staff = () => import('../views/merchantManagement/staff.vue')
const staffdetail = () => import('../views/merchantManagement/staffdetail.vue')
const business = () => import('../views/merchantManagement/business.vue')
const contract = () => import('../views/merchantManagement/contract.vue')
const commodity = () => import('../views/merchantManagement/commodity.vue')
const commoditydetail = () => import('../views/merchantManagement/commoditydetail.vue')
const takeaway = () => import('../views/merchantManagement/takeaway.vue')
const takeawaydetail = () => import('../views/merchantManagement/takeawaydetail.vue')
const commodityclass = () => import('../views/merchantManagement/commodityclass.vue')
const businesspz = () => import('../views/merchantManagement/businesspz.vue')

//会员管理
const vipList = () => import("../views/vip/vipList.vue");
const vipDetail = () => import("../views/vip/vipDetail.vue");
const vipjfDetail = () => import("../views/vip/vipjfDetail.vue");
const vipdhDetail = () => import("../views/vip/vipdhDetail.vue");
const vipwxDetail = () => import("../views/vip/vipwxDetail.vue");
const vipyeDetail = () => import("../views/vip/vipyeDetail.vue");
const inviteDetail = () => import("../views/vip/inviteDetail.vue");
const invitejlDetail = () => import("../views/vip/invitejlDetail.vue");

//订单管理
const orderlist = () => import("../views/order/Order.vue");
const helporder = () => import("../views/order/helporder.vue");

//营销管理
const invitegift = () => import("../views/makerting/invitegift.vue");
const vippz = () => import("../views/makerting/vippz.vue");
const daysign = () => import("../views/makerting/daysign.vue");
const coupon = () => import("../views/makerting/coupon.vue");
const coupondetail = () => import("../views/makerting/coupondetail.vue");
const receivecoupon = () => import("../views/makerting/receivecoupon.vue");
const usecoupon = () => import("../views/makerting/usecoupon.vue");
//积分管理
const integraltype = () => import("../views/integral/integraltype.vue");
const integralsjlist = () => import("../views/integral/integralsjlist.vue");
const sjlistdetail = () => import("../views/integral/sjlistdetail.vue");
const integralsplist = () => import("../views/integral/integralsplist.vue");
const splistdetail = () => import("../views/integral/splistdetail.vue");

const integraldetail = () => import("../views/integral/integraldetail.vue");
const integralrule = () => import("../views/integral/integralrule.vue");

//代理商管理
const agentlist = () => import("../views/agent/agentlist");
const agentset = () => import("../views/agent/agentset");
//财务管理
const merchantbilling = () => import("../views/finance/merchantbilling.vue");
const merchantcollection = () => import("../views/finance/merchantcollection.vue");
const merchantwallet = () => import("../views/finance/merchantwallet.vue");
const agentbilling = () => import("../views/finance/agentbilling");
const agentcollection = () => import("../views/finance/agentcollection");
const agentwallet = () => import("../views/finance/agentwallet");
const riderbilling = () => import("../views/finance/riderbilling.vue");
const ridercollection = () => import("../views/finance/ridercollection");
const riderwallet = () => import("../views/finance/riderwallet");
const invoice = () => import("../views/finance/invoice");
//礼品卡管理
const giftlist = () => import("../views/giftcard/giftlist");
const change = () => import("../views/giftcard/change");
const tag = () => import("../views/giftcard/tag");
//骑手管理
const riderlist = () => import("../views/ridermanage/riderlist");
const riderdetail = () => import("../views/ridermanage/riderlist/riderdetail");
const riderbill = () => import("../views/ridermanage/riderbill");
const takeoutdeliveryrule = () => import("../views/ridermanage/takeoutdeliveryrule");
const hbaddeliveryrule = () => import("../views/ridermanage/hbaddeliveryrule");

// 反馈管理
const feedbackManagement = () => import('../views/feedbackManagement/index.vue');

// 修改密码
const changePassword = () => import('../views/systemConfig/changePassword/index.vue');
const opearteLog = () => import('../views/systemConfig/opearteLog/index.vue');


Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/index" },
  { path: "/login", component: Login },
  {
    path: "/index",
    component: Index,
    redirect: "/Welcome",
    children: [
      { path: "/index", component: Welcome },
      { path: "/employee", component: employeeManagement },
      { path: "/employee/details", component: employeeDetail },
      { path: "/organization", component: OrganizationManagement },
      { path: "/post", component: Post },
      { path: "/menu", component: Menu },
      { path: "/authority", component: Authority },
      { path: "/organizationType", component: OrganizationTypeMaintenance },
      { path: "/myAccountView", component: myAccountView },
      { path: "/roleMenu", component: RoleMenu },
      { path: "/systemConfig", component: SystemConfig },
      { path: "/systemConfig/details", component: SystemConfigDetails },
      { path: "/tableDesign", component: TableDesign },
      { path: '/coding', component: Table },
      { path: '/table/details', component: TableDetails },
      { path: '/tableDdl', component: TableDdl },
      { path: '/tableDdl/details', component: TableDdlDetails },
      { path: '/loginLog', component: LoginLog },
      { path: '/loginLog/details', component: LoginLogDetails },


      { path: '/fromDesigner', name: 'designer', component: FromDesigner },
      { path: '/dialog', name: 'dialogTest', component: DialogTest },
      { path: '/queryDialog', name: 'queryDialogTest', component: QueryDialogTest },
      { path: '/view', name: 'view', component: View },
      { path: '/table', name: 'table', component: DesignTable },

      //经营数据
      { path: '/platformdata', component: platformdata },
      { path: '/auditBusinessone', component: auditBusinessone },
      { path: "/auditdetail", component: auditdetail },
      { path: '/auditBusinesstwo', component: auditBusinesstwo },
      { path: "/auditdetailtwo", component: auditdetailtwo },

      { path: "/exChange", component: exChange },
      { path: "/tip", component: tip },
      //商家管理

      { path: "/diamond", component: diamond },
      { path: "/staff", component: staff },
      { path: "/staffdetail", component: staffdetail },

      { path: "/business", component: business },
      { path: "/contract", component: contract },
      { path: "/commodity", component: commodity },
      { path: "/commoditydetail", component: commoditydetail },

      { path: "/takeaway", component: takeaway },
      { path: "/takeawaydetail", component: takeawaydetail },
      { path: "/commodityclass", component: commodityclass },
      { path: "/businesspz", component: businesspz },
      //会员管理
      { path: "/vipList", component: vipList },
      { path: "/vipDetail", component: vipDetail },
      { path: "/vipjfDetail", component: vipjfDetail },
      { path: "/vipdhDetail", component: vipdhDetail },
      { path: "/vipwxDetail", component: vipwxDetail },
      { path: "/vipyeDetail", component: vipyeDetail },
      { path: "/inviteDetail", component: inviteDetail },
      { path: "/invitejlDetail", component: invitejlDetail },
      //订单管理
      { path: "/orderlist", component: orderlist },
      { path: "/helporder", component: helporder },
      //营销管理
      { path: "/invitegift", component: invitegift },
      { path: "/vippz", component: vippz },
      { path: "/daysign", component: daysign },
      { path: "/coupon", component: coupon },
      { path: "/coupondetail", component: coupondetail },
      { path: "/receivecoupon", component: receivecoupon },
      { path: "/usecoupon", component: usecoupon },
      //积分管理
      { path: "/integraltype", component: integraltype },
      { path: "/integralsjlist", component: integralsjlist },
      { path: "/sjlistdetail", component: sjlistdetail },

      { path: "/integralsplist", component: integralsplist },
      { path: "/splistdetail", component: splistdetail },
      { path: "/integraldetail", component: integraldetail },
      { path: "/integralrule", component: integralrule },
      //代理商管理
      { path: "/agentlist", component: agentlist },
      { path: "/agentset", component: agentset },
      //财务管理
      { path: "/merchantbilling", component: merchantbilling },
      { path: "/merchantcollection", component: merchantcollection },
      { path: "/merchantwallet", component: merchantwallet },
      { path: "/agentbilling", component: agentbilling },
      { path: "/agentcollection", component: agentcollection },
      { path: "/agentwallet", component: agentwallet },
      { path: "/riderbilling", component: riderbilling },
      { path: "/ridercollection", component: ridercollection },
      { path: "/riderwallet", component: riderwallet },
      { path: "/invoice", component: invoice },

      //礼品卡管理
      { path: "/giftlist", component: giftlist },
      { path: "/change", component: change },
      { path: "/tag", component: tag },
      //骑手管理
      { path: "/riderlist", component: riderlist, children: [] },
      { path: "/riderdetail", component: riderdetail },
      { path: "/riderbill", component: riderbill },
      { path: "/takeoutdeliveryrule", component: takeoutdeliveryrule },
      { path: "/hbaddeliveryrule", component: hbaddeliveryrule },

      // 反馈管理
      { path: "/feedbackManagement", component: feedbackManagement },
      { path: "/changePassword", component: changePassword },
      { path: "/opearteLog", component: opearteLog },




    ]
  }
];

const router = new VueRouter({
  routes
});

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    let token = ""; //token保存在localstorage中

    let item = localStorage.getItem("token");
    // 先将拿到的试着进行json转为对象的形式
    try {
      item = JSON.parse(item);
    } catch (error) {
      // eslint-disable-next-line no-self-assign
      item = item;
    }
    if (item && item.startTime) {
      let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      let timel = date - item.startTime;
      console.log("timelength:" + timel + ",expires:" + item.expires);
      if (timel < item.expires) {
        token = item.value;
      }
    }
    if (token === null || token === "") {
      // debugger
      next("/login");
    } else {

      next();
    }
  }
});

export default router;
